body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  padding: 0.5rem 2rem 0.5rem 2rem;
  border: 1px solid black;
  border-radius: 4rem !important;
  transition: 0.3s all;
}

.sign_in {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
button:hover {
  background-color: #0056b3;
  color: #fff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transform: translateY(-5px) scale(1.05);
}


.profile{
  position: absolute;
  left: 59%;
}


.profile_style {
 
  transition: 0.3s all;

}

.profile_style:hover {
  color: red;
}