body {
  height: 100%;
  background-image: url('../../assets/img/sarah-dorweiler-x2Tmfd1-SgA-unsplash.jpg');
  background-size: cover;
  min-height: 100vh;
  width: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
  overflow: hidden;
}

.container {
  position: absolute;
  top: 100px;
  left: 900px;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-style: none;
  border-color: rgb(35,109,183);
  width: 400px;
}

@media (min-width: 1400px) {
  input {
    background: rgba(255,255,255,0.5);
    border-style: solid;
    padding: 4px 3px 4px 14px;
    border-radius: 8px;
  }
}

.container {
}

.tips {
  position: absolute;
  top: 500px;
}

@media (min-width: 1650px) {
  .positioning {
    position: absolute;
    left: 1200px;
  }
}

@media (min-width: 1400px) {
  .positioning {
  }
}

.row {
}

.flex {
  display: flex;
  flex-direction: column;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: rgb(33,37,41);
}

